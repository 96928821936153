<script lang="ts">
import eventBus from '../utils/eventBus.js'

export default {
	methods: {
		showSuccessToast(message = 'changesSuccessfullyProcessed', isTranslated = false) {
			const toastMessage = isTranslated ? message : this.$t('text.' + message)
			eventBus.$emit('addToastMessage', toastMessage, 'success')
		},
		showErrorToast(message = 'errorProcessingChanges', isTranslated = false) {
			const toastMessage = isTranslated ? message : this.$t('text.' + message)
			eventBus.$emit('addToastMessage', toastMessage, 'error')
		},
		showWarningToast(message = 'genericWarning', isTranslated = false) {
			const toastMessage = isTranslated ? message : this.$t('text.' + message)
			eventBus.$emit('addToastMessage', toastMessage, 'warn')
		},
	},
}
</script>