<template>
	<!-- Service Provider Navbar-->
	<template v-if="!appIsServiceManager">
		<v-text-field variant="outlined" density="compact" clearable hide-details prepend-inner-icon="mdi-magnify"
			placeholder='Search' v-model="searchString" @keyup.enter="search" data-cy="search" @click:clear="clear"
		/>
		<IfFeatureFlag not flag="FEATURE_FAQ_IN_SERVICES">
			<FaqsMovedLink v-if="userIsServiceProviderOrImpersonating" />
		</IfFeatureFlag>
		<v-btn-toggle>
			<v-btn id="btnShowServices" class="gradientButton" :class="{ active: pathname === '/services' }"
				@click="$router.push('/services')"
			>
				<v-icon class="d-lg-none">mdi-cart</v-icon>
				<span class="d-none d-lg-inline">{{ $t('text.services') }}</span>
			</v-btn>
			<v-btn v-if="$store.state.featureFlags.FEATURE_FAQ_IN_SERVICES"
				class="gradientButton" id="btnShowFAQs"
				:class="{ active: pathname === '/faq-list' }" @click="$router.push('/faq-list')"
			>
				<v-icon class="d-lg-none">mdi-frequently-asked-questions</v-icon>
				<span class="d-none d-lg-inline">{{ $t('text.faqs') }}</span>
			</v-btn>
			<v-btn v-if="$store.state.featureFlags.FEATURE_PACKAGE_DESIGNER && serviceProviderHasTemplateTypes"
				class="gradientButton" id="btnShowPackages" :class="{ active: pathname === '/packages' }"
				@click="$router.push('/packages')"
			>
				<v-icon class="d-lg-none">mdi-package-variant-closed</v-icon>
				<span class="d-none d-lg-inline">{{ $t('text.packages') }}</span>
			</v-btn>
		</v-btn-toggle>

		<slot name="add-button"></slot>
	</template>

	<!-- Operator Navbar -->
	<template v-if="appIsServiceManager">
		<v-text-field variant="outlined" density="compact" clearable hide-details prepend-inner-icon="mdi-magnify"
			placeholder='Search' v-model="searchString" @keyup.enter="search()" data-cy="search-field" @click:clear="clear()"
		/>
		<IfFeatureFlag not flag="FEATURE_FAQ_IN_SERVICES">
			<FaqsMovedLink v-if="userIsServiceProviderOrImpersonating" />
		</IfFeatureFlag>
		<v-btn-toggle>
			<v-btn id="btnShowServices" class="gradientButton" :class="{ active: pathname === '/services' }"
				@click="$router.push('/services')">
				<v-icon class="d-lg-none">mdi-cart</v-icon>
				<span class="d-none d-lg-inline">{{ $t('text.services') }}</span>
			</v-btn>
			<v-btn v-if="$store.state.featureFlags.FEATURE_PACKAGE_DESIGNER && serviceProviderHasTemplateTypes" class="gradientButton" id="btnShowPackages"
				:class="{ active: pathname === '/packages' }" @click="$router.push('/packages')">
				<v-icon class="d-lg-none">mdi-package-variant-closed</v-icon>
				<span class="d-none d-lg-inline">{{ $t('text.packages') }}</span>
			</v-btn>
		</v-btn-toggle>

	</template>
</template>

<script>
import IfFeatureFlag from '../../../components/ifFeatureFlag/IfFeatureFlag.vue'
import FaqsMovedLink from '../serviceDesigner/FaqsMovedLink.vue'
import Common from '../../../mixins/Common.vue'

export default {
	name: 'PackagesNavbar',
	mixins: [ Common ],
	components: { IfFeatureFlag, FaqsMovedLink },
	props: {
		fetchData: Function,
		onSearch: Function,
		onClear: Function,
	},
	data() {
		return {
			searchString: this.$store.state.searchString,
			filter: null,
		}
	},
	computed: {
		pathname() {
			return this.$route.path
		},
		appIsServiceManager() {
			return this.$store.state.selectedComponent?.fields?.title?.en === 'Manage Services'
		},
		serviceProviderHasTemplateTypes() {
			return this.$store.state.selectedServiceProvider?.fields?.userAccount?.de?.fields?.serviceTypes?.de.length > 0
		},
	},
	methods: {
		async search() {
			await this.$store.commit('setSearchString', this.searchString)
			this.onSearch?.()
			this.fetchData({ total: true })
		},
		async clear() {
			this.searchString = ''
			this.onClear?.()

			await this.$store.commit('setSearchString', null)

			this.fetchData({ total: true })
		},
	},
}
</script>
