<template>
	<div class="contractor-form">
		<FieldSet :label="$t('text.yourData')" :info-text="$t('text.yourDataInfoText')" />

		<FieldSet class="mt-6" :label="$t('text.companyDetails')">
			<Field class="mt-4" typeName="BusinessProfileData" fieldName="businessName"
				v-model="contractor.businessProfileData.businessName" dataCy="businessName"
				:info-text="$t('text.contractBusinessNameInfoText')" />
			<Field class="mt-4" typeName="BusinessProfileData" fieldName="companyEmail"
				v-model="contractor.businessProfileData.companyEmail" dataCy="companyEmail" />
			<Field class="mt-4" typeName="BusinessProfileData" fieldName="uidNumber"
				v-model="contractor.businessProfileData.uidNumber" dataCy="uidNumber" />
		</FieldSet>

		<FieldSet class="mt-6" :label="$t('text.companyAddress')">
			<Field class="mt-4" typeName="BusinessProfileData" fieldName="streetAddress"
				v-model="contractor.businessProfileData.streetAddress" dataCy="streetAddress" />
			<div class="d-flex" style="gap: 10px;">
				<Field style="width: 100%;" class="mt-4" typeName="BusinessProfileData" fieldName="zipCode"
					v-model="contractor.businessProfileData.zipCode" dataCy="zipCode" />
				<Field style="width: 100%;" class="mt-4" typeName="BusinessProfileData" fieldName="city"
					v-model="contractor.businessProfileData.city" dataCy="city" />
			</div>
			<Field class="mt-4" typeName="BusinessProfileData" fieldName="country"
				v-model="contractor.businessProfileData.country" dataCy="country" />
		</FieldSet>

		<FieldSet class="mt-6" :label="$t('text.communicationSetting')"
			:info-text="$t('text.communicationSettingInfoText')">
			<Field class="mt-4" typeName="BusinessProfileData" fieldName="invoiceRecipientName"
				v-model="contractor.businessProfileData.invoiceRecipientName" dataCy="invoiceRecipientName" />
			<Field class="mt-4" typeName="Contractor" fieldName="email" v-model="contractor.email" dataCy="email" />
		</FieldSet>

		<FieldSet class="mt-6" :label="$t('text.bankingDetails')" :info-text="$t('text.bankingDetailsInfoText')">
			<Field class="mt-4" typeName="BusinessProfileData" fieldName="accountHolder"
				v-model="contractor.businessProfileData.accountHolder" dataCy="accountHolder" />
			<Field class="mt-4" typeName="BusinessProfileData" fieldName="iban"
				v-model="contractor.businessProfileData.iban" dataCy="iban" />
			<Field class="mt-4" typeName="BusinessProfileData" fieldName="bic"
				v-model="contractor.businessProfileData.bic" dataCy="bic" />
			<Field class="mt-4" typeName="BusinessProfileData" fieldName="creditInstitution"
				v-model="contractor.businessProfileData.creditInstitution" dataCy="creditInstitution" />
		</FieldSet>
	</div>
</template>

<script>
import Common from '@/mixins/Common.vue'
import Field from '@/components/fields/Field.vue'
import FieldSet from '@/views/applications/packageDesigner/FieldSet.vue'
import ChildErrorReceiver from '../../views/applications/packageDesigner/ChildErrorReceiver.vue'

export default {
	name: 'ContractorForm',
	components: { Field, FieldSet },
	mixins: [Common, ChildErrorReceiver],
	props: {
		modelValue: Object,
	},
	data() {
		return {
			contractor: {
				businessProfileData: {}
			}
		}
	},
	watch: {
		childErrorMessage() {
			if (this.childErrorMessage) {
				this.$emit('update:hasFormError', true)
			}
			else {
				this.$emit('update:hasFormError', false)
			}
		},
		contractor: {
			deep: true,
			handler(n) {
				this.$emit('update:modelValue', n)
			},
		},
	},
	mounted() {
		this.contractor = { ...this.modelValue }
	}
}
</script>

<style lang="scss">
.contractor-form {
	.FieldSet {
		.infoText,
		>.helpText {
			font-size: 12px;
			font-weight: 400;
			line-height: 16px;
			font-style: normal;
			letter-spacing: 0.001px;
			color: black;
		}

		.FieldSet-label {
			font-size: 17px;
			font-weight: 500;
			line-height: 22px;
			letter-spacing: -0.071px;
			color: black;
		}

		.Field label.title {
			font-size: 15px;
			font-weight: 500;
			line-height: 20px;
			letter-spacing: -0.037px;
			color: black;
		}
	}
}
</style>