<template>
	<Disclosure
		:title="$t('text.categoryInfoTitle')"
		:error="sectionMessage.error"
		:message="sectionMessage.message"
		:expanded="false"
		data-cy="categoryInfo"
		ref="SECTION_categoryInfo"
	>
		<div class="field left-border">
			<v-label>{{ $t('text.productCategory') }} <span class="error-text">({{ $t('text.required') }})</span>
			</v-label>
			<div class="row" style="z-index:2; position:relative">
				<ChipsList :items="chipsLabels" @click="openDialog" :data-cy="dataCy">
					<AIAutoGenerateButton
						:disabled="disabled || !featureEnabled('AI-product-categories')"
						:content-data="aiButtonData"
						ai-action="productCategories"
						@aiResult="handleAutoGenerate"
						@click.stop
					/>
				</ChipsList>
				<Dialog ref="productCategoriesDialog"
								:confirmLabel="$t('text.confirmSelection')"
								:cancelLabel="$t('text.discardChanges')"
								:confirm-handler="confirmDialog"
								:width="'800px'"
								:title="$t('text.productCategory')">
					<template #content>
						<InheritedSelector
							:items="items"
							:selected-items="selectedItems"
							:sub-level-name="'subProductCategories'"
						/>
					</template>
				</Dialog>
			</div>
			<p v-if="categoryErrors.length"><span style="color:#ff5252 !important;">{{ categoryErrors[0] }}</span></p>
			<p class="helpText" v-html="$t('text.productCategoryDesc')"/>
		</div>
	</Disclosure>
</template>

<script>
import Dialog from '@/components/common/Dialog.vue'
import InheritedSelector from '@/components/common/selectors/InheritedSelector.vue'
import Common from '@/mixins/Common.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import isEqual from 'lodash/isEqual'
import AIAutoGenerateButton from '@/components/common/AIAutoGenerateButton.vue'
import Loading from '@/mixins/Loading.vue'
import Toast from '@/mixins/Toast.vue'
import ProFeatureTag from '@/components/common/ProFeatureTag.vue'
import ChipsList from '@/components/common/selectors/ChipsList.vue'

// TODO: the binding in this class is quite non-standard, we  should fix this.
//       this already created issues where all dropdowns got broken.

export default {
	name: "ProductCategoriesSelector",

	components: {
		ChipsList,
		ProFeatureTag,
		Dialog,
		InheritedSelector,
		Disclosure,
		AIAutoGenerateButton,
	},
	mixins: [Common, Toast, Loading],

	props: {
		app: String,
		dataPayload: Object,
		disabled: Boolean,
		serviceProvider: Object,
		workflowStatus: String,
		dataCy: {type: String, default: undefined}
	},

	data() {
		return {
			categories: [],
			selectedCategories: [],
			initSelectedCategories: [],
			items: [],
			chips: [],
			selectedItems: [],
			sectionMessage: {
				error: false,
				message: ''
			},
			categoryErrors: [],
			// we need this flag because sometimes the validateAllFields method is called from the parent via refs and sometimes the code within this method is executed before the method initialSelectedCategories in created
			initialCategoriesSet: false
		}
	},
	watch: {
		selectedCategories: {
			handler(newModel) {
				this.selectedCategoriesChanged()
			},
			immediate: true
		},
		categoryErrors(n) {
			if (n.length > 0) {
				this.setSectionError(this.sectionMessage, this.$t('text.categoryRequiredError'))
			} else {
				this.resetSectionError(this.sectionMessage)
			}
		},
	},
	async created() {
		await this.getProductCategories()
		this.initialSelectedCategories()
	},
	computed: {
		chipsLabels() {
			return this.chips.map(chip => chip.count ? chip.title[this.selectedLocale] + ' +' + chip.count : chip.title[this.selectedLocale])
		},
		selectedServiceProvider() {
			return this.$store.state.selectedServiceProvider
		},
		aiButtonData() {
			const addressContactInfoType = this.selectedServiceProvider?.fields?.contactInfos?.de?.filter(contactInfo => contactInfo.fields?.contactInfoType?.de?.fields?.type?.de === 'Address')
			let address = ''
			const contactAddress = addressContactInfoType[0]?.fields?.contactAddresses?.de?.[0]
			if (addressContactInfoType?.length && contactAddress?.fields) {
				const { streetAddress, city, country, zipCode } = contactAddress.fields
				address = ''
					+ (streetAddress?.de ?? '') + ' '
					+ (zipCode?.de ?? '') + ' '
					+ (city?.de ?? '') + ' '
					+ (country?.de ?? '')
				address = address.trim()
			}
			return {
				title: this.dataPayload.fields.title,
				longDescription: this.dataPayload.fields.longDescription,
				shortDescription: this.dataPayload.fields.shortDescription,
				address,
			}
		}
	},
	methods: {
		sendData() {
			const data = {
				productCategories: {
					de: []
				},
			}
			//Remove duplicates
			data.productCategories.de = Array.from(new Set(this.selectedItems))

			for (let selectedItem of this.selectedItems) {
				delete selectedItem.sys.selected
			}

			const selected = this.removeSubCategoriesAndIconsForDiff(this.selectedItems)
			const initSelected = this.removeSubCategoriesAndIconsForDiff(this.initSelectedCategories)

			data.changed = !isEqual(selected, initSelected)

			return data
		},
		validateAllFields(action) {
			let allFieldsAreValid = true
			this.resetSectionError(this.sectionMessage)
			if (!this.validateCategory(action)) {
				allFieldsAreValid = false
				this.setSectionError(this.sectionMessage, this.$t('text.categoryRequiredError'))
			}

			return allFieldsAreValid
		},
		validateCategory(action) {
			let isValid = true
			this.categoryErrors = []
			if (this.initialCategoriesSet && this.selectedItems.length === 0) {
				isValid = false
				this.categoryErrors.push(this.$t('text.categoryRequiredError'))
			}
			const spTrustLevel = this.serviceProvider.fields.trustLevel?.de
			if (!this.userIsOperator && !isValid && spTrustLevel <= 2 && this.workflowStatus !== 'approved' && action !== 'approve' && action !== 'activate') {
				isValid = true
				this.categoryErrors = []
			}
			return isValid
		},
		selectedCategoriesChanged() {
			if (this.selectedCategories && this.selectedCategories.length > 0) {
				if (!this.items || !this.items.length) {
					this.items = JSON.parse(JSON.stringify(this.categories))
					this.selectedItems = JSON.parse(JSON.stringify(this.selectedCategories))
				}
				this.chips = []
				this.initialSelect(this.items, this.selectedItems, null)
				this.addMissingParentCategories()
				this.addChipsIntoInput()
			}
		},
		removeSubCategoriesAndIconsForDiff(data) {
			//Remove Subcategories for lodash compare otherwise it returns a false positive
			const copyData = JSON.parse(JSON.stringify(data))
			if (copyData?.length) {
				for (const category of copyData) {
					delete category?.fields?.subProductCategories
					delete category?.fields?.icon
				}
			}
			return copyData
		},
		openDialog() {
			if (this.disabled) return
			this.$refs.productCategoriesDialog.show = true
			// Only reset items if they're empty
			if (!this.items.length) {
				this.items = JSON.parse(JSON.stringify(this.categories))
			}
		},
		chipsLabel(chip) {
			return chip.count ? chip.title[this.selectedLocale] + ' +' + chip.count : chip.title[this.selectedLocale]
		},
		confirmDialog() {
			this.chips = []
			this.selectedItems = []
			this.updateSelected()
			this.addChipsIntoInput()
			this.validateCategory()

			this.$forceUpdate()
			return true
		},
		editSelected(item) {
			this.selectedItems.push(item)
		},
		updateSelected(items) {
			items = items || this.items
			for (const item of items) {
				if (item.sys.selected === true) {
					this.editSelected(item)
				}

				if (item.fields?.subProductCategories?.de) {
					this.updateSelected(item.fields.subProductCategories.de)
				}
			}
		},
		addChipsIntoInput(subCategories, categoriesCount) {
			if (!subCategories) {
				for (const item of this.items) {
					if (!categoriesCount && categoriesCount !== 0) {
						if (item.sys.selected) {
							this.chips.push({
								title: item.fields.title,
								id: item.sys.id,
								count: item.fields.subProductCategories ? this.addChipsIntoInput(item.fields.subProductCategories.de, 0) : 0
							})
						} else if (item.fields.subProductCategories?.de && item.fields.subProductCategories?.de?.length > 0) {
							this.addChipsIntoInput(item.fields.subProductCategories.de)
						}
					}
				}
			} else if (subCategories) {
				for (const subCategory of subCategories) {
					if (!categoriesCount && categoriesCount !== 0 && subCategory.sys.selected) {
						this.chips.push({
							title: subCategory.fields.title,
							id: subCategory.sys.id,
							count: subCategory.fields.subProductCategories ? this.addChipsIntoInput(subCategory.fields.subProductCategories.de, 0) : 0
						})
					} else {
						if (subCategory.sys.selected) {
							++categoriesCount
						}
					}
				}
				return categoriesCount
			}
		},
		initialSelect(items, selectedItems, parentCategory) {
			if (items) {
				for (const item of items) {
					if (item.fields) {
						if (selectedItems.some(el => el.sys?.id === item.sys?.id)) {
							item.sys.selected = true

							if (parentCategory) {
								parentCategory.sys.selected = true
							}
						}

						if (item.fields.subProductCategories?.de && selectedItems) {
							this.initialSelect(item.fields.subProductCategories.de, selectedItems, item)
						}
					}
				}
			}
		},
		addMissingParentCategories() {
			for (const item of this.items) {
				var haveParentCategory = false

				if (item.sys.selected) {
					for (const selectedItem of this.selectedCategories) {
						if (selectedItem.sys.id === item.sys.id) {
							haveParentCategory = true
							break
						}
					}

					if (!haveParentCategory) {
						this.selectedItems.push(item)
					}
				}
			}
		},
		initialSelectedCategories() {
			const clientAssignment = this.dataPayload.fields.clientAssignments.de.find(
				(el) => el.fields.client.de.sys.id === this.$store.state.selectedClient.sys.id
			)

			this.selectedCategories = clientAssignment?.fields?.productCategories?.de ?? []
			this.initSelectedCategories = JSON.parse(JSON.stringify(this.selectedCategories))
			this.initialCategoriesSet = true
		},
		async getProductCategories() {
			try {
				this.categories = this.$store.state.selectedClient.fields.productCategories.de.filter(x => x.fields?.mainCategory?.de === true)
				if (this.categories) {
					this.categories.sort(this.compare)
				}

				if (this.categories?.length > 0) {
					for (let categories of this.categories) {
						if (categories.fields?.subProductCategories?.de) {
							categories.fields.subProductCategories.de.sort(this.compare)
						}
					}
				}
			} catch (error) {
				this.showError(error)
			}
		},

		handleAutoGenerate(response) {
			console.log('handleAutoGenerate', response)
			if (Array.isArray(response) && response.length) {
				// Preserve existing selections by copying them
				const existingSelections = JSON.parse(JSON.stringify(this.selectedItems))

				// Keep existing items and their current state
				if (!this.items.length) {
					this.items = JSON.parse(JSON.stringify(this.categories))
				}

				// Restore existing selections
				this.initialSelect(this.items, existingSelections, null)

				// Add new categories from AI response
				response.forEach(categoryObject => {
					// Only add if not already selected
					const isAlreadySelected = existingSelections.some(item =>
						item.sys.id === categoryObject.id
					)
					if (!isAlreadySelected) {
						this.addCategory(categoryObject.id)
					}
				})

				this.confirmDialog()
			}
		},
		addCategory(categoryId) {
			const findAndSelectCategory = (items) => {
				for (const item of items) {
					if (item.sys.id === categoryId) {
						item.sys.selected = true
						return true
					}
					if (item.fields?.subProductCategories?.de) {
						if (findAndSelectCategory(item.fields.subProductCategories.de)) {
							item.sys.selected = true
							return true
						}
					}
				}
				return false
			}

			findAndSelectCategory(this.items)
		}
	},
}
</script>
