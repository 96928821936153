<template>
	<div class="customer-segment-tags-selector">
		<Disclosure
			:title="$t('text.customerSegmentTagsTitle')"
			:error="sectionMessage.error"
			:message="sectionMessage.message"
			data-cy="customerSegmentTags" ref="SECTION_customerSegmentTagsInfo"
			:expanded="false"
		>
			<div class="field left-border">
				<v-label v-if="userIsOperator">
					{{ $t('text.customerSegmentTags') }}
					<span class="error-text">({{ $t('text.required') }})</span>
				</v-label>

				<div class="row" style="z-index:2; position:relative">
					<ChipsList :items="selectedCSTNames" @click="onChipsListClick" :disabled="!userIsOperator" data-cy="customerSegmentTags-chips">
						<AIAutoGenerateButton
							v-if="userIsOperator"
							:disabled="!featureEnabled('AI-customer-segment-tags')"
							:content-data="aiButtonData"
							ai-action="customerSegmentTags"
							@click.stop
							@aiResult="model = { isAiGenerated: true, values: [ $event ] }; onUpdateModel()"
						/>
					</ChipsList>
					<DataDialog ref="customerSegmentTagsDialog"
											v-model="model"
											@update:modelValue="model.isAiGenerated = false; onUpdateModel()"
											:title="$t('text.customerSegmentTags')"
											:useWrap="true"
					>
						<template #content="{ wrap }">
							<div class="field left-border">
								<v-label>{{$t('text.customerSegmentTags')}}</v-label>
								<p class="helpText" v-html="$t('text.customerSegmentTagsDescDialog')"/>
								<div v-for="(cst, s) of contentHubCustomerSegmentTags" :key="'cst-' + s" class="cst-select-row">
									<v-checkbox
										v-model="wrap.model.values"
										hide-details
										:label="cst.name.de"
										:value="cst.sys.id"
									/>
								</div>
							</div>
						</template>
					</DataDialog>
				</div>
				<p v-if="errors.length"><span style="color:#ff5252 !important;">{{ errors[0] }}</span></p>
				<p v-if="model.isAiGenerated" class="aiGeneratedText" v-html="$t('text.customerSegmentTagsAiGenerated')"/>
				<p v-if="userIsOperator" class="helpText" v-html="$t('text.customerSegmentTagsDesc')"/>
				<p v-else class="helpText" v-html="$t('text.customerSegmentTagsDescServiceProvider')"/>
			</div>
		</Disclosure>
	</div>
</template>

<script>
import Disclosure from '../common/Disclosure.vue'
import InheritedSelector from "@/components/common/selectors/InheritedSelector.vue";
import Dialog from "@/components/common/Dialog.vue";
import AIAutoGenerateButton from "@/components/common/AIAutoGenerateButton.vue";
import ProFeatureTag from "@/components/common/ProFeatureTag.vue";
import Common from "@/mixins/Common.vue";
import ChipsList from '@/components/common/selectors/ChipsList.vue'
import DataDialog from "@/components/common/DataDialog.vue";

export default {
	components: {DataDialog, ChipsList, ProFeatureTag, AIAutoGenerateButton, Dialog, InheritedSelector, Disclosure},
	mixins: [ Common ],
	props: {
		modelValue: Object,
		dataPayload: Object,
	},
	data: () => ({
		contentHubCustomerSegmentTags: [],
		initialCustomerSegmentTags: null,
		model: {
			isAiGenerated: false,
			values: [],
		},
		errors: [],
		sectionMessage: {
			error: false,
			message: ''
		},
	}),
	computed: {
		selectedCSTNames() {
			return this.model.values.map(id => this.contentHubCustomerSegmentTags.find(chcst => chcst.sys.id === id)?.name.de).filter(v => v)
		},
		selectedServiceProvider() {
			// we can work with the selectedServiceProvider because customerSegmentTagsInfo is in step marketplace
			// switching steps updates the selectedServiceProvider, which then always holds the latest title, longDescription, shortDescription for the ai request
			return this.$store.state.selectedServiceProvider
		},
		aiButtonData() {
			const addressContactInfoType = this.selectedServiceProvider?.fields?.contactInfos?.de?.filter(contactInfo => contactInfo.fields?.contactInfoType?.de?.fields?.type?.de === 'Address')
			let address = ''
			if (addressContactInfoType?.length && addressContactInfoType[0]?.fields?.contactAddresses?.de?.[0]?.fields) {
				const { streetAddress, city, country, zipCode } = addressContactInfoType[0].fields.contactAddresses.de[0].fields
				address = `${streetAddress?.de ?? ''} ${zipCode?.de ?? ''} ${city?.de ?? ''} ${country?.de ?? ''}`.trim()
			}
			return {
				title: this.dataPayload?.fields?.title?.de ?? '',
				longDescription: this.dataPayload?.fields?.longDescription?.de ?? '',
				shortDescription: this.dataPayload?.fields?.shortDescription?.de ?? '',
				address
			}
		}
	},
	watch: {
		errors(n) {
			if (n.length > 0) {
				this.setSectionError(this.sectionMessage, this.$t('text.customerSegmentTagsRequiredError'))
			} else {
				this.resetSectionError(this.sectionMessage)
			}
		},
		modelValue(n) {
			// TODO changing the BusinessProfile/ServiceDesigner mounted() to created() could make this watcher obsolte
			this.initModel()
		},
	},
	methods: {
		async loadCustomerSegmentTags() {
			const clientId = this.$store.state.selectedClient.sys.id
			try {
				return await this.$httpGet(`/customerSegmentTags?clientId=${clientId}`)
			}
			catch (e) {
				return []
			}
		},
		sendData() {
			return {
				customerSegmentTags: {
					de: this.model,
				},
			}
		},
		validateAllFields() {
			let allFieldsAreValid = true
			this.resetSectionError(this.sectionMessage)
			if (!this.validate()) {
				allFieldsAreValid = false
				this.setSectionError(this.sectionMessage, this.$t('text.customerSegmentTagsRequiredError'))
			}
			return allFieldsAreValid
		},
		validate() {
			let isValid = true
			this.errors = []
			if (this.model.values.length === 0) {
				isValid = false
				this.errors.push(this.$t('text.customerSegmentTagsRequiredError'))
			}
			return isValid
		},
		checkSelectionHasChanged() {
			if (this.model.values?.length !== this.initialCustomerSegmentTags?.length) {
				return true
			}
			return this.model.values?.some((val, index) => val !== this.initialCustomerSegmentTags[index])
		},
		onUpdateModel() {
			this.validate()
			this.$emit('change', this.checkSelectionHasChanged())
		},
		onChipsListClick() {
			if (!this.userIsOperator) {
				return
			}
			this.$refs.customerSegmentTagsDialog.open()
		},
		initModel() {
			// modelValue might not be available upon created(), initModel will be triggered again from watch
			if (!this.modelValue) {
				return
			}
			// init model only once
			if (this.initialCustomerSegmentTags) {
				return
			}

			this.initialCustomerSegmentTags = this.modelValue.values ?? []

			if (!this.contentHubCustomerSegmentTags?.length) {
				// keep original customer segment tags in case the CH request failed or returned empty
				this.model.values = this.initialCustomerSegmentTags
			} else {
				// otherwise check original customer segment tags against the ones from CH
				this.model.values = this.initialCustomerSegmentTags.filter(cst => this.contentHubCustomerSegmentTags.some(chr => chr.sys?.id === cst))
			}
		},
	},
	async created() {
		let contentHubResult = await this.loadCustomerSegmentTags()
		this.contentHubCustomerSegmentTags = contentHubResult.filter(entry => entry.name?.de)
		this.initModel()
	},
}
</script>

<style scoped lang="scss">
.customer-segment-tags-selector {
	.cst-select-row {
		display: flex;
		gap: 10px;
		align-items: center;
	}
}
</style>