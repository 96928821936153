<script lang="ts">
import { reactive } from 'vue'
import {
	CategorizeProductResponse,
	type CustomerSegmentsRequest,
	CustomerSegmentsResponse,
	type TranslationRequest,
	type TranslationResponse
} from '../../../shared/freddy'
import AiExecutive, { GuessCategoryRequest, GuessCustomerSegmentTagsRequest } from '../../../api2/src/modules/ai/AiExecutive'

export type AiLoadingStatus = 'LOADING' | 'DONE' | 'ERROR' | 'CANCELLED'

export class AiLoadingItem {
	id: string
	status: AiLoadingStatus = 'LOADING'
	promise: Promise<any>
	callback: Function

	constructor(id: string, promise: Promise<any>, callback: Function) {
		this.id = id
		this.promise = promise
		this.callback = callback

		;(async () => {
			this.status = 'LOADING'
			try {
				const r: TranslationResponse | CustomerSegmentsResponse = await promise
				if (this.status as AiLoadingStatus == 'CANCELLED') return
				// ATT: for some reason we have to set status = 'DONE' not here, but in the callback
				//      otherwise the status is not reactive
				//      maybe we should move the whole promise handline to the mixin for that reason..
				//      we would also save one level of callback nesting
				await callback(r)
			}
			catch (e) {
				// in error case we have to set status = 'ERROR' in the callback for the same reason as above
				await callback(null, e ?? true)
				return
			}
		})()
	}

	cancel() {
		// TODO: can we cancel the promise?
		this.status = 'CANCELLED'
	}
}

export class AiTextLoadingItem extends AiLoadingItem {
	locale: string
	constructor(fieldId: string, locale: string, promise: Promise<any>, callback: Function) {
		super(fieldId, promise, callback)
		this.locale = locale
	}
}

export type TranslateableFieldType = 'Symbol' | 'Text' | 'RichText'

export default {
	provide() {
		return {
			// we provide ourselves to the children
			aiContext: this,
		}
	},
	data: () => ({
		aiContext_loadingItems: [] as AiTextLoadingItem[],
	}),
	computed: {
		// TODO: this is a hack to get the current service provider id
		serviceProviderId() {
			return this.$store.state.selectedServiceProvider?.sys?.id
		},
		translationSettings() {
			return this.$store.state.translationSettings
		}
	},
	methods: {
		async aiContext_loadTranslationSettings() {
			try {
				const result = await this.$httpGet(`/aiSettings/${ this.serviceProviderId }`)
				if (result.translationSettings) {
					await this.$store.commit('setTranslationSettings', result.translationSettings)
				}
			} catch (error) {
				console.log(error)
			}
		},
		aiContext_mapFieldType(type: TranslateableFieldType): 'text' | 'html' {
			if (type == 'RichText') return 'html'
			return 'text'
		},
		aiContext_buildTranslationsRequest(toLocale: string, settings): TranslationRequest {
			return {
				context: {
					tone: settings.tone,
					writingStyle: settings.writingStyle,
					customPrompt: settings.customPrompt?.de ? settings.customPrompt.de : undefined,
				},
				data : {
					toLocale,
					translateables: [],
				},
			}
		},
		aiContext_getLoadingItem(id: string, locale?: string): AiLoadingItem | undefined {
			// generic search for any subclasses of AiLoadingItem
			return this.aiContext_loadingItems.find(i => i.id === id && (!locale || i.locale === locale))
		},
		aiContext_addTextLoadingItem(request: TranslationRequest, key: string, callback: Function, promise: Promise = undefined): AiTextLoadingItem {
			const previousItemIndex = this.aiContext_loadingItems.findIndex(i => i.id === key && i.locale === request.data.toLocale)
			if (previousItemIndex !== -1) {
				// Cancel to prevent callback from writing back the translation response
				this.aiContext_loadingItems[previousItemIndex].cancel()
				// Remove existing item from array before creating a new one with the same key
				this.aiContext_loadingItems.splice(previousItemIndex, 1)
			}

			const item = reactive(new AiTextLoadingItem(
				key,
				request.data.toLocale,
				promise,
				async (r: TranslationResponse, error = undefined) => {
					if (error) {
						this.aiContext_getLoadingItem(key, request.data.toLocale)!.status = 'ERROR'
						await callback(null, null, error)
						return
					}
					this.aiContext_getLoadingItem(key, request.data.toLocale)!.status = 'DONE'
					// the toLocale is needed in the callback to write result to proper field locale
					await callback(request.data.toLocale, r)
				},
			))
			this.aiContext_loadingItems.push(item)
			return item
		},
		aiContext_addLoadingItem(request: CustomerSegmentsRequest | GuessCategoryRequest, key: string, callback: Function, promise: Promise): AiTextLoadingItem {
			const previousItemIndex = this.aiContext_loadingItems.findIndex(i => i.id === key)
			if (previousItemIndex !== -1) {
				// Cancel to prevent callback from writing back the translation response
				this.aiContext_loadingItems[previousItemIndex].cancel()
				// Remove existing item from array before creating a new one with the same key
				this.aiContext_loadingItems.splice(previousItemIndex, 1)
			}

			const item = reactive(new AiLoadingItem(
				key,
				promise,
				async (r: CustomerSegmentsResponse | CategorizeProductResponse, error = undefined) => {
					if (error) {
						this.aiContext_getLoadingItem(key)!.status = 'ERROR'
						await callback(null, error)
						return
					}
					this.aiContext_getLoadingItem(key)!.status = 'DONE'
					await callback(r)
				},
			))
			this.aiContext_loadingItems.push(item)
			return item
		},
		async aiContext_translate(
			fields: Object,
			fromLocale: string,
			toLocales: string[],
			callback: Function,
		) {
			for (const toLocale of toLocales) {
				if (toLocale == fromLocale) continue

				const request: TranslationRequest = this.aiContext_buildTranslationsRequest(toLocale, this.translationSettings)

				for (const field of fields) {
					const fromText = field.value[fromLocale]
					if (!fromText) continue
					const type: TranslateableFieldType = this.aiContext_mapFieldType(field.field.type)
					const key = field.id
					request.data.translateables.push({ key, type, fromLocale, fromText })
				}

				// we create multiple loading items for the same promise, those will all resolve at the same time.
				const promise = this.$httpPost('/aiTranslation', request)

				for (const translateable of request.data.translateables) {
					this.aiContext_addTextLoadingItem(request, translateable.key, callback, promise)
				}
			}
		},
		async aiContext_getCustomerSegmentTags(
			customerSegmentTagsData: GuessCustomerSegmentTagsRequest,
			callback: Function,
		) {
			let aiExecutive = new AiExecutive(this)
			const promise = aiExecutive.guessCustomerSegmentTags(this.$store.state.selectedClient.sys.id, customerSegmentTagsData)
			this.aiContext_addLoadingItem(customerSegmentTagsData, 'customerSegmentTags', callback, promise)
		},
		async aiContext_getProductCategories(
			productCategoryData: GuessCategoryRequest,
			callback: Function,
		) {
			let aiExecutive = new AiExecutive(this)
			const promise = aiExecutive.guessCategory(this.$store.state.selectedClient.sys.id, productCategoryData)
			this.aiContext_addLoadingItem(productCategoryData, 'productCategories', callback, promise)
		},
		aiContext_cancel() {
			this.aiContext_loadingItems.forEach(i => i.cancel())
		},
	},
	created() {
		// load the settings once for the context
		this.aiContext_loadTranslationSettings()
	},
	beforeUnmount() {
		this.aiContext_cancel()
	},
}
</script>