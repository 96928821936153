import { CheckPolicy, Resource } from '../../framework/permission-util'
import { RT } from '../permissions'
import Executive from '../../framework/rmi-util'
import { register } from '../../framework/rmi-server-util'
import { assignContract, ContractTemplateForListView, deleteContractSequence, deleteContractTemplate, getContractor, getContractorsForList, getContractorWithFields, getContractSequences, getContractTemplateDetails, getContractTemplates, getContractTemplateWithFields, importContractors, setContractNeeded, upsertContractTemplate, updateContractor, signContracts, ContractorFilterItem, getContractorSalesChannels, generateViewContractsToken, getContractsAndContractorsByToken, getContractors, bulkUpdateContractors, sendKafkaMessage, subscribeToKafkaTopic, sendReminder, getContractorsWithoutContract, getContractTemplatesForClient, deleteCascadeContractors } from './ContractTechnical'
import { Contract, Contractor, ContractSequence, ContractTemplate } from './contract.entities'

export default class ContractExecutive extends Executive {

	initServer() {
	}

	//@CheckPolicy()
	async importContractors(@Resource(RT.CLIENT) clientId: string, contractorType: string): Promise<void> {
		return await importContractors(clientId, contractorType)
	}

	//@CheckPolicy()
	async upsertContractTemplate(@Resource(RT.CLIENT) clientId: string, @Resource(RT.CONTRACTTEMPLATE) template: ContractTemplate): Promise<ContractTemplate> {
		return await upsertContractTemplate(clientId, template)
	}

	// @CheckPolicy()
	async getContractTemplates(@Resource(RT.CLIENT) clientId: string): Promise<ContractTemplateForListView[]> {
		return await getContractTemplates(clientId)
	}

	// @CheckPolicy()
	async getContractSequences(@Resource(RT.CLIENT) clientId: string): Promise<ContractSequence[]> {
		return await getContractSequences(clientId)
	}

	// @CheckPolicy()
	async getContractTemplateDetails(@Resource(RT.CLIENT) clientId: string, @Resource(RT.CONTRACTTEMPLATE) templateId: string): Promise<ContractTemplate> {
		return await getContractTemplateDetails(templateId)
	}

	// @CheckPolicy()
	async getContractTemplateWithFields(@Resource(RT.CLIENT) clientId: string, @Resource(RT.CONTRACTTEMPLATE) templateId: string, options: { fields: string[], populate: string[] }): Promise<ContractTemplate> {
		return await getContractTemplateWithFields(templateId, options)
	}

	// @CheckPolicy()
	async assignContract(@Resource(RT.CLIENT) clientId: string, @Resource(RT.CONTRACTSEQUENCE) contractTemplateId: string, @Resource(RT.CONTRACTOR) contractorIds: string[]): Promise<{assignedCount: number, totalCount: number}> {
		return await assignContract(clientId, contractTemplateId, contractorIds)
	}

	// @CheckPolicy()
	async signContracts(@Resource(RT.CLIENT) clientId: string, @Resource(RT.CONTRACTOR) contractorId: string, @Resource(RT.CONTRACT) contractIds: string[], signatureName: string, signaturePosition: string, token: string): Promise<void> {
		return await signContracts(contractIds, signatureName, signaturePosition, token)
	}

	// @CheckPolicy()
	async setContractNeeded(@Resource(RT.CLIENT) clientId: string, @Resource(RT.CONTRACTOR) contractorIds: string[], needsContract: boolean): Promise<void> {
		return await setContractNeeded(contractorIds, needsContract)
	}

	//@CheckPolicy()
	async getContractors(@Resource(RT.CLIENT) clientId: string): Promise<Contractor[]> {
		return await getContractors(clientId)
	}

	//@CheckPolicy()
	async updateContractor(@Resource(RT.CONTRACTOR) contractor: Contractor, fieldsToUpdate: string[]): Promise<void> {
		await updateContractor(contractor, fieldsToUpdate)
	}

//	@CheckPolicy()
	async getContractor(@Resource(RT.CONTRACTOR) contractorId: string): Promise<Contractor> {
		return await getContractor(contractorId)
	}

	//@CheckPolicy()
	async getContractorWithFields(@Resource(RT.CONTRACTOR) contractorId: string, fields: string[]): Promise<Contractor> {
		return await getContractorWithFields(contractorId, fields)
	}

	//@CheckPolicy()
	async getContractorsForList(@Resource(RT.CLIENT) clientId: string, @Resource(RT.CONTRACTORFILTERS) filters: ContractorFilterItem[]): Promise<Contractor[]> {
		return await getContractorsForList(clientId, filters)
	}

	//@CheckPolicy()
	async getContractorSalesChannels(@Resource(RT.CLIENT) clientId: string): Promise<any[]> {
		return await getContractorSalesChannels(clientId)
	}

	// TODO: do we need the client Id to perform some permission checks? Potentially the user Id as well?
	//@CheckPolicy()
	async deleteContractTemplate(@Resource(RT.CLIENT) clientId: string, @Resource(RT.CONTRACTTEMPLATE) templateId: string): Promise<void> {
		return await deleteContractTemplate(templateId)
	}

	// TODO: do we need the client Id to perform some permission checks? Potentially the user Id as well?
	//@CheckPolicy()
	async deleteContractSequence(@Resource(RT.CLIENT) clientId: string, @Resource(RT.CONTRACTSEQUENCE) sequenceId: string): Promise<void> {
		return await deleteContractSequence(sequenceId)
	}

	//@CheckPolicy()
	async generateViewContractsToken(@Resource(RT.CLIENT) clientId: string, @Resource(RT.CONTRACTOR) email: string): Promise<{ token: string, expiresIn: string }> {
		return await generateViewContractsToken(email)
	}

	//@CheckPolicy()
	async getContractsAndContractorsByToken(@Resource(RT.CLIENT) clientId: string, token: string): Promise<{
		contractors: Contractor[],
		contractsByContractor: Record<string, Contract[]>,
		clientNames: Record<string, string>
	}> {
		return await getContractsAndContractorsByToken(token)
	}

	//@CheckPolicy()
	async bulkUpdateContractors(@Resource(RT.CLIENT) clientId: string, @Resource(RT.CONTRACTOR) contractors: Contractor[], token: string): Promise<void> {
		return await bulkUpdateContractors(contractors, token)
	}

	//@CheckPolicy()
	async sendReminder(@Resource(RT.CLIENT) clientId: string, @Resource(RT.CONTRACTOR) contractorIds: string[], ignoreRecentlySent: boolean): Promise<{sentCount: number, totalCount: number}> {
		return await sendReminder(clientId, contractorIds, ignoreRecentlySent)
	}

	//@CheckPolicy()
	async sendKafkaMessage(@Resource(RT.CLIENT) clientId: string, topic: string, data: any): Promise<void> {
		return await sendKafkaMessage(topic, data)
	}

	//@CheckPolicy()
	async subscribeToKafkaTopic(@Resource(RT.CLIENT) clientId: string, topic: string): Promise<void> {
		return await subscribeToKafkaTopic(topic)
	}

	//@CheckPolicy()
	async getContractorsWithoutContract(@Resource(RT.CLIENT) clientId: string): Promise<Number> {
		return await getContractorsWithoutContract(clientId)
	}

	//@CheckPolicy()
	async getContractTemplatesForClient(@Resource(RT.CLIENT) clientId: string): Promise<ContractTemplate[]> {
		return await getContractTemplatesForClient(clientId)
	}

	//@CheckPolicy()
	async deleteCascadeContractors(@Resource(RT.CLIENT) clientId: string): Promise<void> {
		return await deleteCascadeContractors(clientId)
	}
}

register(ContractExecutive)
