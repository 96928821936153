<template>
	<FieldWrap style="margin-top: 16px;" :title="title">
		<v-text-field
			variant="outlined"
			density="compact"
			hide-details
			class="vfield"
			:placeholder="title"
			:error-messages="errorMessages"
			:suffix="suffix"
			@blur="$emit('blur', $event)"
			v-model="model"
			:data-cy="dataCy"
		/>
	</FieldWrap>
</template>

<script>
import FieldWrap from './FieldWrap.vue'

export default {
	components: { FieldWrap },
	props: {
		title: String,
		errorMessages: Array,
		modelValue: String,
		suffix: String,
		dataCy: String,
	},
	computed: {
		model: {
			get() {
				return this.modelValue
			},
			set(n) {
				this.$emit('update:modelValue', n)
			},
		},
	},
}
</script>

<style scoped>
div.vfield .v-input { margin-top: 3px; }
</style>