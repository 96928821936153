import { CheckPolicy, Resource } from '../../framework/permission-util'
import { register } from '../../framework/rmi-server-util'
import Executive from '../../framework/rmi-util'
import { RT } from '../permissions'

export { User, Role } from '../entities'

export default class FastifyTestExecutive extends Executive {

	initServer() {
	}

	async test(param: string): Promise<string> {
		return 'echo:' + param
	}

	@CheckPolicy()
	async testRestricted(@Resource(RT.USER) userId: string): Promise<string> {
		return 'echo:' + userId
	}
}

register(FastifyTestExecutive)