<template>
	<Application class="ContractorsView" :loading="loading" v-model:error-title="errorTitle" :error-detail="errorDetail">
		<template #navbar>
			<div class="navbar">
				<div class="navbar__filter">
					<ContractorFilterCombo v-model="filterComboModel" :disabled-filters="disabledFilters" />
				</div>
				<div class="navbar__magnifier">
					<v-icon :color="'#8F8F8F'">mdi-magnify</v-icon>
				</div>
				<!-- <button class="navbar__search-btn">{{ $t('text.search') }}</button> -->
				<ButtonList
					:buttonText="$t('text.contractsBulkActionText')"
					:options="[
						{ key: 'assignContract', icon: 'mdi-gavel', disabled: !selectedContractors?.length },
						{ key: 'sendReminder', icon: 'mdi-bell', disabled: sendReminderBulkDisabled },
					]"
					:itemsI18n="true"
					prependIcon="mdi-animation"
					@option-click="onBulkClick"
				/>
			</div>
		</template>
		<ContractorsList class="contractors" :contractors="contractorsModelFiltered" :sequenceFilterSet="contractTemplateFilterSet" v-model:offset="offset" v-model:selected="selectedContractors" @updateContractor="updateContractor" />
		<Dialog ref="assignContractDialog"
			data-cy="assignContractDialog"
			:confirmLabel="$t('text.confirm')"
			:cancelLabel="$t('text.cancel')"
			:confirm-handler="checkAssignContractStep"
			:close-handler="onCloseDialog"
			:title="$t('text.contractsAssignDialogTitle')"
			:isValid="!!selectedContractTemplate?.de"
			height="fit-content"
			width="540px"
			class="ContractorsView assign-dialog"
		>
			<template #content>
				<FieldSet v-if="assignContractDialogStep === 'chooseContract'">
					<v-label>{{ $t('text.contractsAssignDialogHelpTitle') }}</v-label>
					<p>{{ $t('text.contractsAssignDialogHelpSubTitle') }}</p>
					<Field :fieldOverride="{ id: 'contractTemplate', name: 'contract', type: 'Symbol', control: { widgetId: 'dropdown' }, localized: false, required: true }" :options="contractTemplatesForField" :modelValue="selectedContractTemplate" :title="$t('text.contractsAssignDialogContractFieldTitle')"></Field>
				</FieldSet>
				<div v-else class="assign-dialog__confirm">
					<div class="assign-dialog__confirm__icon"><v-icon :color="'#00AEEF'" :size="'96px'">mdi-gavel</v-icon></div>
					<div>
						<v-label>{{ $t('text.contractsAssignDialogTitle') }}</v-label>
						<p>{{ $t('text.contractsAssignDialogConfirmText', { contractTemplate: contractTemplatesForField.find(ct => ct.id === selectedContractTemplate.de)?.label, contractorCount: selectedContractors.length }) }}</p>
					</div>
				</div>
			</template>
		</Dialog>
		<Dialog ref="sendReminderDialog"
			data-cy="sendReminderDialog"
			:confirmLabel="$t('text.confirmSendReminder')"
			:cancelLabel="$t('text.cancel')"
			:confirm-handler="sendReminder"
			:close-handler="() => ignoreRecentlySent = false"
			:title="$t('text.contractorSendReminderDialogTitle')"
			height="fit-content"
			width="540px"
			class="ContractorsView send-reminder-dialog"
		>
			<template #content>
				<div class="send-reminder-dialog__wrapper">
					<div class="send-reminder-dialog__icon"><v-icon :color="'#00AEEF'" :size="'96px'">mdi-bell</v-icon></div>
					<div>
						<v-label class="font-weight-bold">{{ $t('text.contractorSendReminderDialogTitle') }}</v-label>
						<p>{{ $t('text.contractorSendReminderDialogConfirmText', { contractorCount: selectedContractors.length }) }}</p>
						<div class="send-reminder-dialog__content d-flex justify-center">
							<v-checkbox density="compact" hide-details v-model="ignoreRecentlySent" />
							<p class="text-wrap ml-3 text-sm" style="width: fit-content;">{{ $t('text.contractorSendReminderDialogCheckbox') }}</p>
						</div>
					</div>
				</div>
			</template>
		</Dialog>
	</Application>
</template>

<script lang="ts">
import Application from '../Application.vue'
import ContractorsList from '../../../components/contract/ContractorsList.vue'
import ContractExecutive from '../../../../../api2/src/modules/contract/ContractExecutive'
import ContractorFilterCombo from '../../../components/searchFilter/ContractorFilterCombo.vue'
import Toast from '../../../mixins/Toast.vue'
import { isEqual } from 'lodash'
import Dialog from '../../../components/common/Dialog.vue'
import Field from '../../../components/fields/Field.vue'
import FieldSet from '../packageDesigner/FieldSet.vue'
import type { ContractorFilterItem } from '../../../../../api2/src/modules/contract/ContractTechnical'
import ButtonList from '@/components/common/ButtonList.vue'

export default {
	components: { ContractorsList, Application, ContractorFilterCombo, Dialog, FieldSet, Field, ButtonList },
	mixins: [ Toast ],
	data: () => ({
		contractors: [] as any[],
		contractorsModel: [] as any[],
		contractorsModelFiltered: [] as any[],
		loading: false,
		errorTitle: '',
		errorDetail: '',
		filterComboModel: { filters: [] as any[], search: '' },
		searchTimeout: null as any,
		contractTemplates: [] as any,
		selectedContractTemplate: { de: null },
		selectedContractors: [],
		assignContractDialogStep: 'chooseContract',
		ignoreRecentlySent: false,
		offset: 0,
		lastRequestFilters: [] as ContractorFilterItem[],
	}),
	computed: {
		sendReminderBulkDisabled(): boolean {
			return !this.selectedContractors?.length || this.filterComboModel.filters.some(f => f.field === 'noContracts' && f.value === true)
		},
		contractTemplateFilterSet(): boolean {
			return this.filterComboModel.filters.some(f => f.field === 'contractTemplate' && f.value !== 'all')
		},
		disabledFilters(): string[] {
			return this.filterComboModel.filters.map(f => f.field)
		},
		contractTemplatesForField() {
			return this.contractTemplates.map(ct => ({ id: ct.id, label: ct.name}))
		},
		filtersForRequest(): ContractorFilterItem[] {
			return this.filterComboModel.filters?.map(f => ({
				field: f.field,
				mode: f.mode,
				value: f.value,
			})).filter(f => !!f.value && f.value !== 'all')
		}
	},
	watch: {
		'filterComboModel.filters': {
			deep: true,
			handler(n) {
				const filters = JSON.stringify(this.filtersForRequest)
				if (JSON.stringify(this.lastRequestFilters) === filters) return

				this.lastRequestFilters = JSON.parse(filters)
				this.loadContractors()
			},
		},
		'filterComboModel.search'(n) {
			if (this.searchTimeout) clearTimeout(this.searchTimeout)
			if (!n.length) {
				this.contractorsModelFiltered = this.contractorsModel
				return
			}
			this.searchTimeout = window.setTimeout(() => {
				this.contractorsModelFiltered = this.contractorsModel.filter(c => c.name.toLowerCase().includes(n.toLowerCase()))
			}, 200)
		},
	},
	methods: {
		async loadContractors() {
			try {
				this.loading = true
				this.offset = 0
				this.selectedContractors = []
				let contractExecutive = new ContractExecutive(this)
				this.contractors = await contractExecutive.getContractorsForList(this.$store.state.selectedClient.sys.id, this.filtersForRequest)
				this.contractorsModel = this.mapContractorResults(this.contractors)
				this.contractorsModelFiltered = this.contractorsModel.filter(c => c.name.toLowerCase().includes(this.filterComboModel.search.toLowerCase()))
			}
			catch (error) {
				this.errorTitle = this.$t('text.ERROR')
				this.errorDetail = error.response ? error.response.error : error
			}
			finally {
				this.loading = false
			}
		},
		async loadContractTemplates() {
			try {
				let contractExecutive = new ContractExecutive(this)
				this.contractTemplates = await contractExecutive.getContractTemplates(this.$store.state.selectedClient.sys.id)
			}
			catch (error) {
				this.errorTitle = this.$t('text.ERROR')
				this.errorDetail = error.response ? error.response.error : error
			}
		},
		mapContractorResults(contractors) {
			return contractors.map(r => {
				const contractorStatus = r.hasAtLeastOneContract ? 'assigned' : 'missing'
				return {
					id: r.id,
					name: r.name,
					email: r.email,
					noEmail: !r.email?.length,
					productKinds: r.productKinds,
					salesChannels: r.salesChannelObjects.map(sc => sc?.title?.de),
					contractorStatus,
					contractStatus: 'TODO',
					contracts: r.contracts || [],
					lastReminderDate: r.lastReminderDate,
					peakId: r.peakId,
					streetAddress: r.streetAddress,
					zipCode: r.zipCode,
					city: r.city,
				}
			})
		},
		async updateContractor(contractor) {
			try {
				const fieldsToUpdate = ['email', 'name']
				const contractorEntity = this.contractors.find(c => c.id === contractor.id)
				let contractExecutive = new ContractExecutive(this)
				await contractExecutive.updateContractor({ 
					...contractorEntity,
					email: contractor.email,
					name: contractor.name
				}, fieldsToUpdate)
				
				await this.loadContractors()
				this.showSuccessToast(this.$t('text.updateContractorSuccess'))
			}
			catch (error) {
				this.showErrorToast(this.$t('text.updateContractorError'))
			}
		},
		checkAssignContractStep() {
			if (this.assignContractDialogStep === 'chooseContract') {
				this.assignContractDialogStep = 'confirm'
				return false
			}
			else {
				this.confirmAssignContract()
			}
		},
		async confirmAssignContract() {
			this.loading = true
			try {
				let contractExecutive = new ContractExecutive(this)
				const result = await contractExecutive.assignContract(this.$store.state.selectedClient.sys.id, this.selectedContractTemplate.de, this.selectedContractors)
				// TODO show number of contracts that have been assigned
				await this.loadContractors()
				
				this.showSuccessToast(this.$t('text.assignContractSuccess', { assignedCount: result.assignedCount, totalCount: result.totalCount }), true)
				this.resetAssignContractDialog()
			}
			catch (error) {
				console.log(error)
				this.showErrorToast(this.$t('text.assignContractError'), true)
			}
			finally {
				this.loading = false
			}
		},
		async sendReminder() {
			this.loading = true
			try {
				let contractExecutive = new ContractExecutive(this)
				const { sentCount, totalCount } = await contractExecutive.sendReminder(this.$store.state.selectedClient.sys.id, this.selectedContractors, this.ignoreRecentlySent)
				await this.loadContractors()
				
				this.showSuccessToast(this.$t('text.sendReminderSuccess', { sentCount, totalCount }), true)
			}
			catch (error) {
				console.log(error)
				this.showErrorToast(this.$t('text.sendReminderError'), true)
			}
			finally {
				this.loading = false
			}
		},
		onCloseDialog() {
			this.assignContractDialogStep = 'chooseContract'
			this.selectedContractTemplate = { de: null }
		},
		resetAssignContractDialog() {
			this.assignContractDialogStep = 'chooseContract'
			this.selectedContractTemplate = { de: null }
			this.selectedContractors = []
			this.$refs['assignContractDialog'].show = false
		},
		onBulkClick(optionKey) {
			if (optionKey === 'assignContract') {
				this.$refs['assignContractDialog'].show = true
			}
			else if (optionKey === 'sendReminder') {
				this.$refs['sendReminderDialog'].show = true
			}
		},
	},
	async created() {
		await this.loadContractors()
		await this.loadContractTemplates()
	}
}
</script>

<style scoped lang="scss">
.ContractorsView {
	.navbar {
		display: flex;
		gap: 24px;
		align-items: center;
		flex-grow: 1;
		height: 100%;
		padding: 12px 0 12px 24px;

		&__magnifier {
			position: absolute;
			left: 24px;
			top: 32px;
			transform: translateY(-50%);
			display: flex;
			align-items: center;
			margin: 0 10px 0 12px;
		}

		&__filter {
			box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
			flex: 1;
		}

		&__search-btn {
			background: var(--color-blue);
			padding: 8px 16px;
			color: white;
			border-radius: 4px;
		}
	}

	.contractors {
		margin-top: 64px;
	}
}
</style>
<style lang="scss">
.ContractorsView {
	.assign-dialog {
		&__confirm {
			display: flex;
			align-items: center;

			&__icon {
				margin-right: 48px;
			}
		}
	}

	.send-reminder-dialog {
		&__wrapper {
			display: flex;
			align-items: center;
		}

		&__icon {
			margin-right: 48px;
		}

		&__content {
			margin-top: 13px;
		}
	}
}
</style>