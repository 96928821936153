<template>
	<Application :loading="loading" v-model:errorTitle="errorTitle" :errorDetail="errorDetail">
		<template #navbar v-if="!addNewProviderClicked">
			<v-text-field variant="outlined" density="compact" clearable hide-details
				id="search"
				prepend-inner-icon="mdi-magnify"
				placeholder='Search'
				style="flex-grow: 1;"
				v-model="searchString"
				@keyup.enter="getServiceProviders()"
				data-cy="search-field"
				@click:clear="clearSearch()"
			/>

			<v-btn id="btnCreate" class="gradientButton" elevation="0" size="x-small" @click="addNewProvider">
				<v-icon>mdi-plus</v-icon>
				<span class="d-none d-md-inline">{{$t('text.addServiceProvider')}}</span>
			</v-btn>

			<FilterMenu v-model="filter" @clear="clearFilter" @apply="applyFilter">
				<FilterFieldWrap>
					<div>{{$t('text.serviceProviderStatus')}}</div>
					<v-select multiple chips hide-details
						ref="vSelectStatus"
						v-model="selectedStatuses"
						:items="statuses"
						:item-title="item => $t('text.' + item.id)"
						:item-value="item => item.id"
						:placeholder="$t('text.allLabel')"
						variant="outlined"
						density="compact"
					>
						<template #selection="{ item }">
							<v-chip :color="item.color" dark>{{$t('text.' + item.id)}}</v-chip>
						</template>
					</v-select>
				</FilterFieldWrap>

				<FilterFieldWrap>
					<div>{{$t('text.serviceType')}}</div>
					<v-combobox multiple chips hide-details closable-chips :return-object="false"
						ref="vSelectCategory"
						:placeholder="$t('text.allLabel')"
						variant="outlined"
						density="compact"
						v-model="selectedProductCategories"
						:items="productCategories"
						:item-title="item => item.title[selectedLocale]"
						:item-value="item => item.value"
					>
						<template #selection="{ item }">
							<v-chip :color="item.color" dark>{{item.fields.title[selectedLocale]}}</v-chip>
						</template>
					</v-combobox>
				</FilterFieldWrap>

				<FilterFieldWrap>
					<div>{{$t('text.clientsTitle')}}</div>
					<v-select multiple chips hide-details
						ref="vSelectMarketplace"
						:placeholder="$t('text.allLabel')"
						variant="outlined"
						density="compact"
						v-model="selectedClients"
						:items="clients"
						:item-title="item => item.fields.title[selectedLocale]"
						:item-value="item => item.sys.id"
					>
						<template #selection="{ item }">
							<v-chip :color="item.color" dark>{{item.fields.title[selectedLocale]}}</v-chip>
						</template>
					</v-select>
				</FilterFieldWrap>
			</FilterMenu>
		</template>

		<v-card class="tableCard">
			<v-data-table fixed-header
				v-model="selected"
				:headers="headers"
				:items="serviceProviders"
				:items-per-page="limit"
				:mobile-breakpoint="700"
				item-key="name"
				class="elevation-0"
				@update:sort-desc="sortTable($event)"
				:item-value="item => item"
			>
				<template v-slot:item="{ item }">
					<tr @click="showProviderDetail(item)">
						<td>
							{{item.fields.title[selectedLocale]}}
						</td>
						<td>{{ getFirstProductCategory(item) }}</td>
<!--						<td>{{ formatDate(item.sys.createdAt) }}</td>-->
						<td style="line-height: 1;">
							<span v-for="cai of item.fields.clientAssignments.de" :key="cai.sys.id">
								<span v-if="cai && cai.fields && cai.fields.status"
									:class="{
										clientAssignmentChip: true,
										[cai.fields.status.de]: true,
									}"
									:title="cai.fields.status.de"
								>
									{{ getClientId(cai) }}
								</span>
							</span>
						</td>
						<td><ApprovalStatus :status="getClientStatus(item)" /></td>
						<td align="center" style="line-height: 100%;">
							<v-tooltip location="bottom" :text="itemStatusTooltip(item)">
								<template #activator="{ props }">
									<div v-bind="props">
										<span v-show="item.addl?.dataStatus === 'no_user_account'"><v-icon color="#f34545">mdi-account-remove</v-icon></span>
										<span v-show="item.addl?.dataStatus === 'complete'"><v-icon color="#64c823">mdi-check-circle</v-icon></span>
										<span v-show="item.addl?.dataStatus === 'incomplete'"><v-icon color="#ffb400">mdi-alert</v-icon></span>
										<span v-show="!item.addl"><v-icon color="#ffb400">mdi-warning</v-icon></span>
									</div>
								</template>
							</v-tooltip>
						</td>
						<td>
							<v-btn class="blueButtonMin" elevation="0" style="color: white; min-width: 30px;"
								v-if="canOperateAs(item)"
								@click.stop="impersonate(item)"
							>
								<v-icon data-cy="operateAs">mdi-drama-masks</v-icon>
								<!--{{ $t('text.operateAs') }}-->
							</v-btn>
						</td>
					</tr>
				</template>
				<template #bottom>
					<TablePaginator v-model="offset" :limit="limit" :total="serviceProviderCount" @update:modelValue="getServiceProviders()" :results="serviceProviders" />
				</template>
			</v-data-table>
		</v-card>
	</Application>
</template>

<script>
import Loading from 'vue-loading-overlay'
import ApprovalStatus from '@/components/common/ApprovalStatus.vue'
import Alert from '@/components/common/Alert.vue'
import TablePaginator from '@/components/common/TablePaginator.vue'
import Common from '@/mixins/Common.vue'
import Application from '../Application.vue'
import FilterMenu from '@/components/common/FilterMenu.vue'
import FilterFieldWrap from '@/components/common/FilterFieldWrap.vue'

export default {
	components: { Loading, ApprovalStatus, Alert, TablePaginator, Application, FilterMenu, FilterFieldWrap },
	mixins: [ Common ],
	data() { return {
		loading: false,
		offset: this.$store.state.offset,
		limit: 15,
		searchSubmitted: false,
		errorTitle: '',
		errorDetail: '',
		selected: [],
		singleSelect: '',
		serviceProviders: [],
		serviceProviderCount: 0,

		searchString: this.$store.state.searchString,
		addNewProviderClicked: false,
		filter: this.$store.state.filter,
		statuses: [
			{ id: 'pending', color: '#ff9e21', dark: true },
			{ id: 'reapprove', color: '#ffb400' },
			{ id: 'approved', color: '#64c823', dark: true },
			{ id: 'deactivated', color: '#f34545', dark: true },
			{ id: 'declined', color: '#f34545', dark: true },
		],
		selectedStatuses: this.$store.state.filter ? this.$store.state.filter.statuses : [],
		validFromDate: this.$store.state.filter ? this.$store.state.filter.validFromDate : '',
		validToDate: this.$store.state.filter ? this.$store.state.filter.validToDate : '',
		productCategories: [],
		selectedProductCategories: this.$store.state.filter ? this.$store.state.filter.productCategories : [],
		clients: [],
		selectedClients: this.$store.state.filter ? this.$store.state.filter.selectedClients : [],
		showFromDate: false,
		showToDate: false,
		isNegative:  this.$store.state.filter ? this.$store.state.filter.isNegative : false,
		dropdownOpen: false,
	}},
	computed: {
		headers () {
			return [
				{ title: this.$t('text.providerName'), key: "provider_name", width: '40%', sortable: false, cellClass: 'truncate' },
				{ title: this.$t('text.category'), key: "category", width: '15%', sortable: false, cellClass: 'truncate' },
//				{ title: this.$t('text.registrationDate'), key: "registration_date", width: '10%', sortable: false, cellClass: 'truncate' },
				{ title: this.$t('text.clientAssignments'), key: "client_assignments", width: '20%', sortable: false },
				{ title: this.$t('text.approval'), key: "status", width: '15%', sortable: false },
				{ title: this.$t('text.status'), key: "data_status", width: '50px', align: 'center', sortable: false },
				{ title: '', key: "operate_as", width: '10%', sortable: false },
			]
		},
		validFromText() {
			if (this.validFromDate === '' || this.isNegative) {
				return this.$t('text.validFrom')
			}
			else return this.validFromDate
		},
		validToText() {
			if (this.validToDate === '' || this.isNegative) {
				return this.$t('text.validUntil')
			}
			else return this.validToDate
		},
	},
	watch: {
		'$store.state.selectedLocale': function() {
			this.selectedLocale = this.$store.state.selectedLocale
		},
	},
	methods: {
		itemStatusTooltip(item) {
			if (item.addl?.dataStatus === 'no_user_account') {
				return this.$t('text.noUserAccount')
			} else if (item.addl?.dataStatus === 'complete') {
				return this.$t('text.complete')
			} else if (item.addl?.dataStatus === 'incomplete') {
				return this.$t('text.incomplete')
			} else {
				return this.$t('text.incomplete')
			}
		},
		userHasAccount(item) {
			return item?.fields?.userAccount?.de?.sys?.id?.length
		},
		getFirstProductCategory(serviceProvider) {
			const currentCA = serviceProvider.fields.clientAssignments?.de?.find(x => x?.fields?.client?.de?.sys?.id === this.$store.state.selectedClient.sys.id)
			return currentCA?.fields?.productCategories?.de?.[0]?.fields?.title?.[this.selectedLocale]
		},
		getClientStatus(serviceProvider) {
			const currentCA = serviceProvider.fields.clientAssignments.de.find(x => x?.fields?.client?.de?.sys?.id === this.$store.state.selectedClient.sys.id)
			return currentCA?.fields?.status?.de
		},
		canOperateAs(serviceProvider) {
			const currentCA = serviceProvider.fields.clientAssignments.de.find(x => x?.fields?.client?.de?.sys?.id === this.$store.state.selectedClient.sys.id)
			if (currentCA?.fields?.isHomebase?.de === true && serviceProvider.fields.userAccount?.de?.sys?.id) {
				return true
			}
			return false
		},
		getClientId(cai) {
			return cai?.fields?.client?.de?.fields?.clientId?.de ? cai.fields.client.de.fields.clientId.de : ''
		},
		addNewProvider() {
			this.addNewProviderClicked = true
			this.showNewProvider()
		},
		applyFilter() {
			if (this.selectedStatuses.length > 0 || this.validFromDate.length > 0 || this.validToDate.length > 0 || this.selectedProductCategories.length > 0 || this.selectedClients.length > 0 || this.isNegative) {
				this.filter = {
					statuses: this.selectedStatuses,
					validFromDate: this.validFromDate,
					validToDate: this.validToDate,
					isNegative: this.isNegative,
					productCategories: this.selectedProductCategories,
					selectedClients: this.selectedClients
				}
				this.$store.commit('setFilter', this.filter)
				this.getServiceProviders()
			}
			else {
				this.clearFilter()
			}
		},
		clearFilter() {
			this.selectedStatuses = []
			this.validFromDate = ''
			this.validToDate = ''
			this.isNegative = false
			this.selectedProductCategories = []
			this.selectedClients = []
			this.filter = null
			this.$store.commit('setFilter', this.filter)
			this.offset = 0
			this.getServiceProviders()
		},
		toggleDatePicker(pickerToToggle) {
			if (pickerToToggle === 'from') {
				this.showToDate = false
				this.showFromDate = true
			} else {
				this.showFromDate = false
				this.showToDate = true
			}
		},
		resetDates() {
			this.validToDate = ''
			this.showFromDate = false
		},
		closeDropDown(ref) {
			if (!this.dropdownOpen) {
				this.$refs[ref].focus()
				this.dropdownOpen = true
			} else {
				this.$refs[ref].blur()
				this.dropdownOpen = false
			}
		},
		async getProductCategories() {
			try {
				const productCategories = this.$store.state.selectedClient.fields.productCategories.de
				if (productCategories) { productCategories.sort(this.compare) }

				if (productCategories?.length > 0) {
					for (let productCategory of productCategories) {
						if (productCategory.fields?.subProductCategories?.de) {productCategory.fields.subProductCategories.de.sort(this.compare)}
					}
				}
				this.productCategories = productCategories.map(item => ({
					title: item.fields?.title ?? {},
					value: item.sys.id,
				}))//.slice(0, 10)
			}
			catch (error) {
				this.showError(error)
			}
		},
		async getMarketplaceClientsForClient(client) {
			let clients = []
			const clientSysId = client.sys.id
			let marketplaces = await this.$httpGet('/marketplace?clientSysId=' + clientSysId)
			let mp = marketplaces?.[0]
			let lookup = {}
			for (let client of mp.fields.externalClients.de) {
				clients.push(client)
				lookup[client.sys.id] = client
			}
			// add homeClient if it wasnt in the external clients
			if (!lookup[mp.fields.homeClient.de.sys.id])
				clients.push(mp.fields.homeClient.de)
			this.clients = clients
		},
		async sortTable(event) {
			let sortMode = event[0]
			await this.getServiceProviders(sortMode)
		},
		async showProviderDetail(item) {
			await this.$store.commit('setSelectedServiceProvider', item)
			this.$router.push('/businessProfile')
		},
		async showNewProvider() {
			await this.$store.commit('setSelectedServiceProvider', null)
			await this.$store.commit('setPeakProducts', null)
			this.$router.push('/businessProfile')
		},
		async getServiceProviders(sortMode) {
			await this.$store.commit('setPeakProducts', null)
			await this.$store.commit('setSelectedServiceProvider', null)

			if (this.searchString) {
				return this.getSearchedServiceProviders()
			}

			this.loading = true
			try {
				await this.$store.commit('setOffset', this.offset)

				let url = `/serviceproviders?clientId=${this.$store.state.selectedClient.sys.id}&skip=${this.offset}&limit=${this.limit + 1}`
				if (this.$store.state.isImpersonation === true && this.$store.state.selectedServiceProvider.fields.tags?.de) {
					url += `&tags=${this.$store.state.selectedServiceProvider.fields.tags.de}`
				}
				if (this.userIsOperator && !this.$store.state.loggedInUser.fields.canSeeObjectsWithTags.de.find(x => x === "ALL")) {
					url += `&tags=${this.$store.state.loggedInUser.fields.canSeeObjectsWithTags.de}`
				}
				if (this.$store.state.filter) {
					url += `&filter=${JSON.stringify(this.$store.state.filter)}`
				}

				if (sortMode != undefined) url += `&sortMode=${sortMode}&selectedLocale=${this.selectedLocale}`

				let res = await this.$httpGet(url)
				this.serviceProviders = res.serviceProviders
				this.serviceProviderCount = res.total
			}
			catch (error) {
				if (error.response?.status == 400) {
					this.serviceProviders = []
				}
				else {
					this.errorTitle = this.$t('text.ERROR')
					this.errorDetail = error.response?.error ?? error
				}
			}
			this.loading = false
		},
		async getSearchedServiceProviders() {
			this.loading = true
			try {
				await this.$store.commit('setOffset', this.offset)
				await this.$store.commit('setSearchString', this.searchString)

				let url = `/search`

				const requestBody = {
					clientId: this.$store.state.selectedClient.sys.id,
					contentType: 'serviceProvider',
					skip: this.offset,
					limit: this.limit + 1,
					searchString: this.searchString
				}

				if (this.userIsOperator && this.$store.state.isImpersonation === false && !this.$store.state.loggedInUser.fields.canSeeObjectsWithTags.de.find(x => x === "ALL")) {
					requestBody.tags = this.$store.state.loggedInUser.fields.canSeeObjectsWithTags.de
				}

				const res = await this.$httpPost(url, requestBody)

				this.serviceProviders = res?.serviceProviders
				this.serviceProviderCount = res?.total ? res.total : 0
			}
			catch (error) {
				if (error.response?.status == 400) {
					this.serviceProviders = []
				}
				else {
					this.errorTitle = this.$t('text.ERROR')
					this.errorDetail = error.response?.error ?? error
				}
			}
			this.loading = false
		},
		async clearSearch() {
			this.offset = 0
			this.searchString = ''
			await this.$store.commit('setSearchString', null)
			
			this.getServiceProviders()
		},
	},
	async mounted() {
		this.getServiceProviders()

		await this.getProductCategories()
		await this.getMarketplaceClientsForClient(this.$store.state.selectedClient)
	},
}
</script>

<style scoped>
input::placeholder { color: red!important; margin-left: 20px; }
.v-data-table__mobile-row { word-break: break-all; }
.v-badge--bordered .v-badge__badge::after { width: 14px !important; height: 14px !important; }
.v-badge__badge {
	width: 14px !important;
	height: 14px !important;
	min-width: 14px !important;
	max-width: 14px !important;
}
.v-tooltip__content {
	color: black !important;
	opacity: 1 !important;
	padding: 5px !important;
	z-index: 999 !important;
	max-height: 28px !important;
}
td { font-size: 12pt !important; }
</style>
