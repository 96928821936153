<template>
	<table class="PriceMatrixMerged" v-if="modelValue">
		<tr>
			<th></th>
			<th v-for="ticketCategory of availableTicketCategories" :key="ticketCategory.sys.id">
				<div class="clip" :title="ticketCategory.fields.title.de">{{ ticketCategory.fields.title.de }}</div>
			</th>
		</tr>
		<tr v-for="ticketType of availableTicketTypes" :key="ticketType.sys.id">
			<th>
				<div class="clip" :title="ticketType.fields.title.de" style="max-width: 250px;">{{ ticketType.fields.title.de }}</div>
			</th>
			<td v-for="ticketCategory of availableTicketCategories" :key="ticketCategory.sys.id">
				<div class="chip"
					v-if="hasPrice(model[ keyFor(ticketCategory, ticketType) ])"
					:style="{
						background: colorFor(ticketCategory, ticketType),
						color: isLightColor(colorFor(ticketCategory, ticketType)) ? '#000' : '#fff'
					}"
				>
					{{ formatPrice(model[ keyFor(ticketCategory, ticketType) ]?.price) }}
					{{ currency }}
				</div>
			</td>
		</tr>
	</table>
</template>

<script lang="ts">
import { matrixCalculatorMixin } from './PriceMatrix.vue'

export default {
	mixins: [ matrixCalculatorMixin ],
	props: {
		ticketTypes: Array,
		ticketCategories: Array,
		profiles: Array,
		// [ { ticketCategory: 'adult', ticketType: '1', price: 0 }, ... ]
		modelValue: Object,
	},
	computed: {
		currency() {
			return this.$store.state.selectedClient?.fields?.currency?.de || '€'
		},
	},
	methods: {
		profileFor(ticketCategory, ticketType) {
			const price = this.model[ this.keyFor(ticketCategory, ticketType) ]
			if (!price) return 'white'
			if (price.sourceId == -1) return { color: '#eeeeee' }
			if (!this.profiles) return 'white'
			const profile = this.profiles.find(p => p.id == price.sourceId)
			return profile
		},
		colorFor(ticketCategory, ticketType) {
			const profile = this.profileFor(ticketCategory, ticketType)
			return profile?.color || 'transparent'
		},
		hasPrice(price) {
			return price?.price !== undefined && price.price !== null && price.price !== ''
		},
		/*
		This function determines if a hex color is considered "light" or "dark"
		by converting the hex values to RGB and checking if their sum is above 500
		
		For example:
		- White (#FFFFFF) -> rgb(255,255,255) = 765 -> light color
		- Black (#000000) -> rgb(0,0,0) = 0 -> dark color
		- Gray (#808080) -> rgb(128,128,128) = 384 -> dark color
		*/
		isLightColor(color: string) {
			if (!color || color === 'transparent') {
				return true
			}
			const r = parseInt(color.slice(1, 3), 16) // Get red value from hex
			const g = parseInt(color.slice(3, 5), 16) // Get green value from hex  
			const b = parseInt(color.slice(5, 7), 16) // Get blue value from hex
			return r + g + b > 500 // If RGB sum > 500, consider it a light color
		},
	},
}
</script>

<style scoped>
.chip { padding: 2px 5px; border-radius: 4px; text-align: center; white-space: nowrap; }
th { text-align: left; }
th,
td { padding-right: 10px; padding-bottom: 10px; }
.clip { max-width: 80px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;  }
th:last-child,
td:last-child { padding-right: 0; }
tr:last-child th,
tr:last-child td { padding-bottom: 0; }
</style>