import { Entity, EntityRepositoryType, PrimaryKey, Property, ManyToOne, OneToMany, Enum } from '@mikro-orm/postgresql'
import { EntityRepository } from '@mikro-orm/postgresql'
import { Field, Regexp, Control, Size, MARKS_OLD_ALL, EnabledNodeTypes, EnabledMarksOld, NODETYPES_TEXT, In  } from '../../framework/model-util'
import { v4 as uuid } from 'uuid'
import { BaseEntity } from '../core/core.entities'

class ContractorRepository extends EntityRepository<Contractor> {}

export enum ContractTemplateStatus {
	NEW = 'NEW',
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE'
}

export enum ContractStatus {
	NEW = 'NEW',
	SENT = 'SENT',
	SIGNED = 'SIGNED',
	REJECTED = 'REJECTED',
	OP_CANCELLED = 'OP-CANCELLED',
	SP_CANCELLED = 'SP-CANCELLED',
	EXPIRED = 'EXPIRED',
	SUPERSEDED = 'SUPERSEDED'
}

/**
 * Defines a contractor's business profile, including basic
 * identifying, contact, and financial details
 */
export class BusinessProfileData {

	@Field({ type: 'Symbol', localized: false, required: true })
	businessName?: string

	@Field({ type: 'Symbol', localized: false, required: true })
	streetAddress?: string

	@Field({ type: 'Symbol', localized: false, required: true })
	zipCode?: string

	@Field({ type: 'Symbol', localized: false, required: true })
	city?: string

	@Field({ type: 'Symbol', localized: false, required: true })
	phoneNumber?: string

	@Control({ widgetId: 'dropdown' })
	@In({ in: [ '<COUNTRIES>' ] })
	@Field({ type: 'Symbol', localized: false, required: true })
	country?: string

	@Field({ type: 'Symbol', localized: false, required: true })
	iban?: string

	@Field({ type: 'Symbol', localized: false, required: true })
	bic?: string

	@Field({ type: 'Symbol', localized: false, required: false })
	uidNumber?: string

	@Field({ type: 'Symbol', localized: false, required: true })
	accountHolder?: string

	@Field({ type: 'Symbol', localized: false, required: true })
	invoiceRecipientName?: string
	
	@Field({ type: 'Symbol', localized: false, required: true })
	@Regexp({ pattern: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$', message: 'regexp-email' })
	companyEmail?: string

	@Field({ type: 'Symbol', localized: false, required: true })
	creditInstitution?: string
}

/**
 * A contractor is the entity that signs contracts with clients.
 * This typically represents a business or a natural person.
 */
@Entity({ repository: () => ContractorRepository })
export class Contractor extends BaseEntity {
	[EntityRepositoryType]?: ContractorRepository

	@PrimaryKey()
	id: string = uuid()

	@Property()
	clientId: string

	@Property()
	@Field({ type: 'Symbol', localized: false, required: true })
	name: string

	@Property()
	@Field({ type: 'Symbol', localized: false, required: false })
	@Regexp({ pattern: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$', message: 'regexp-email' })
	email?: string

	@Property()
	source: number

	@Property()
	peakId?: string

	@Property()
	serviceProviderId?: string

	@Property()
	productKinds: string[]

	@Property()
	salesChannels: string[]

	@Property()
	needsContract: boolean

	@Property()
	contractorType?: string

	@Property()
	storeId?: number

	@Property()
	lastReminderDate?: Date

	@Property({ type: 'json' })
	businessProfileData: BusinessProfileData

	@Property({ default: false })
	active: boolean

	@OneToMany(() => Contract, contract => contract.contractor, { lazy: true })
	contracts: Contract[]

	@OneToMany(() => ContractorContractSequence, ccs => ccs.contractor, { lazy: true })
	contractSequences: ContractorContractSequence[]

	constructor(name: string) {
		super()
		this.name = name
	}
}

class ContractSequenceRepository extends EntityRepository<ContractSequence> {}

/**
 * A contract sequence is an ordered list of contract templates.
 * The idea is that a new version of a contract template supercedes the previous version
 * and is therefore being added to the sequence.
 */
@Entity({ repository: () => ContractSequenceRepository })
export class ContractSequence extends BaseEntity {
	[EntityRepositoryType]?: ContractSequenceRepository

	@PrimaryKey()
	id: string = uuid()

	@Property()
	@Field({ type: 'Symbol', localized: false, required: true })
	name: string

	@Property()
	clientId: string

	@Property({ onCreate: () => new Date() })
	createdAt: Date = new Date()

	@OneToMany(() => ContractTemplate, template => template.contractSequence, { lazy: true })
	contractTemplates: ContractTemplate[]

	@OneToMany(() => ContractorContractSequence, ccs => ccs.contractSequence, { lazy: true })
	contractors: ContractorContractSequence[]

	constructor(name: string) {
		super()
		this.name = name
	}
}

class ContractTemplateRepository extends EntityRepository<ContractTemplate> {}

/**
 * A contract template is a blueprint for a contract.
 * It defines the structure and content.
 */
@Entity({ repository: () => ContractTemplateRepository })
export class ContractTemplate extends BaseEntity {
	[EntityRepositoryType]?: ContractTemplateRepository

	@PrimaryKey()
	id: string = uuid()

	@Property()
	@Field({ type: 'Symbol', localized: false, required: true })
	name: string

	// ORM uses a convention-based naming strategy for relationships: {relationshipPropertyName}Id. Alternatively, you could also be explicit about the mapping: @ManyToOne(() => ContractSequence, { fieldName: 'contractSequenceId' })
	// lazy loading is used to avoid circular dependency
	// TODO: check docs for ManyToOne vs OneToMany
	@ManyToOne(() => ContractSequence, { lazy: true })
	contractSequence: ContractSequence

	@Control({ settings: { maxHeight: '800' } })
	@Property({ type: 'jsonb' })
	@Size({ min: 10, max: 50000 })
	@EnabledMarksOld(MARKS_OLD_ALL)
	@EnabledNodeTypes(NODETYPES_TEXT)
	@Field({ type: "RichText", localized: true, required: true })
	text: Record<string, string>

	@Property()
	productKinds: string[]

	@Property()
	salesChannels: string[]

	@Property({ nullable: true })
	@Control({ settings: { format: "dateonly" } })
	@Field({ type: "Date", localized: false, required: false })
	startDate?: Date

	@Enum(() => ContractTemplateStatus)
	status: ContractTemplateStatus

	@Property()
	@Control({ settings: { unit: "days" } })
	@Field({ type: "Number", localized: false, required: true })
	dueDays: number

	@Property()
	@Control({ widgetId: "switch" })
	@Field({ type: "Boolean", localized: false, required: true })
	needsSignature: boolean

	@OneToMany(() => Contract, contract => contract.contractTemplate, { lazy: true })
	contracts: Contract[]

	constructor(name: string) {
		super()
		this.name = name
	}
}

class ContractorContractSequenceRepository extends EntityRepository<ContractorContractSequence> {}

/**
 * Contractor Contract Sequence is the relation between a contractor and a contract sequence.
 * Essentially an instance of CCS tells us "contractor X is on contract sequence Y",
 * a concrete example could be "Hotel Bergblick has 'Accommodation' contracts.'.
 * When new contract templates are added to the sequence, this relation is being used to know
 * which contractors are affected.
 */
@Entity({ repository: () => ContractorContractSequenceRepository })
export class ContractorContractSequence extends BaseEntity {
	[EntityRepositoryType]?: ContractorContractSequenceRepository

	@PrimaryKey()
	id: string = uuid()

	@ManyToOne(() => Contractor, { lazy: true })
	contractor: Contractor

	@ManyToOne(() => ContractSequence, { lazy: true })
	contractSequence: ContractSequence

	constructor(contractor: Contractor, contractSequence: ContractSequence) {
		super()
		this.contractor = contractor
		this.contractSequence = contractSequence
	}
}

class ContractRepository extends EntityRepository<Contract> {}

/**
 * A contract is a legal agreement between a contractor and a client.
 * It is the instance of a contract template.
 */
@Entity({ repository: () => ContractRepository })
export class Contract extends BaseEntity {
	[EntityRepositoryType]?: ContractRepository

	@PrimaryKey()
	id: string = uuid()

	@ManyToOne(() => Contractor, { lazy: true })
	contractor: Contractor

	@ManyToOne(() => ContractTemplate, { lazy: true })
	contractTemplate: ContractTemplate

	@Property({ onCreate: () => new Date() })
	createdDate: Date = new Date()

	@Property()
	@Field({ type: 'Symbol', localized: true, required: true })
	text: Record<string, string>

	@Property({ type: 'json' })
	commissionRates: Record<string, number>

	@Property({ type: 'json' })
	businessProfileData: BusinessProfileData

	@Enum(() => ContractStatus)
	status: ContractStatus

	@Property({ nullable: true })
	dueDate?: Date

	@Property({ nullable: true })
	signedDate?: Date

	@Property({ nullable: true })
	startDate?: Date

	@Property({ nullable: true })
	endDate?: Date

	@Property({ nullable: true })
	ip?: string

	@Property()
	@Field({ type: 'Symbol', localized: false, required: false })
	signatureName?: string

	@Property()
	@Field({ type: 'Symbol', localized: false, required: false })
	signaturePosition?: string

	@OneToMany(() => ContractHistory, history => history.contract, { lazy: true })
	history: ContractHistory[]

	constructor(contractor: Contractor, contractTemplate: ContractTemplate) {
		super()
		this.contractor = contractor
		this.contractTemplate = contractTemplate
	}
}

class ContractHistoryRepository extends EntityRepository<ContractHistory> {}

/**
 * Contract History tracks all changes and actions performed on a contract
 */
@Entity({ repository: () => ContractHistoryRepository })
export class ContractHistory extends BaseEntity {
	[EntityRepositoryType]?: ContractHistoryRepository

	@PrimaryKey()
	id: string = uuid()

	@ManyToOne(() => Contract, { lazy: true })
	contract: Contract

	@Property()
	date: Date

	@Property()
	action: string

	@Property({ nullable: true })
	userId?: string

	@Property({ type: 'json', nullable: true })
	data?: any

	@Property({ nullable: true })
	summary?: string

	constructor(contract: Contract) {
		super()
		this.contract = contract
		this.date = new Date()
	}
}
