<template>
	<slot></slot>
</template>

<script>
import ChildErrorReceiver from '../../views/applications/packageDesigner/ChildErrorReceiver.vue'

export default {
	props: {
		modelValue: Object,
	},
	mixins: [ ChildErrorReceiver ],
	computed: {
		errors() {
			return this.childErrors
		},
	},
	watch: {
		errors(n) {
			this.$emit('update:modelValue', { errors: n })
		},
	}
}
</script>