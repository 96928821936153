import { SqlEntityRepository } from '@mikro-orm/postgresql'
import { context } from './context-util'

// automatically loads, assigns / creates and persists an entity
export async function autoUpsert(repo: SqlEntityRepository<any>, data: any) {
	// Only search by id rather than all fields to avoid SQL type issues
	let entity = await repo.findOne({ id: data.id })
	if (entity) {
		// TODO: we should use the userSub, but currently we dont have all users in the db for referential integrity..
		//data.updatedBy = context.userSub
		context.em.assign(entity, data as any)
	}
	else {
		// TODO
		//data.createdBy = context.userSub
		entity = await repo.create(data)
	}
	await context.em.persistAndFlush(entity)
	return entity
}