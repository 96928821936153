<template>
	<div class="contract-card" :style="{ 'background-color': backgroundColor, 'color': textColor }">
		<div class="contract-card-top">
			<v-icon class="mr-2">mdi-gavel</v-icon>
			<div class="contract-card-top-name">
				<span v-if="contract.contractTemplate">{{ contract.contractTemplate.name }}</span>
				<span>{{ clientName }}</span>
			</div>
			<div :style="{ 'background-color': backgroundColorChip }" class="contract-card-top-chip">
				<v-icon size="18px">mdi-pencil</v-icon>
				<span>{{ statusText }}</span>
			</div>
		</div>
		<template v-if="!isPhysical">
			<span class="contract-card-info" v-html="infoText"></span>
			<span class="contract-card-issued">{{ issuedOnText }}</span>
		</template>
	</div>
</template>
<script>
import moment from 'moment'
export default {
	props: {
		contract: Object,
		clientName: String
	},
	computed: {
		contractTemplate() {
			return this.contract.contractTemplate
		},
		needsSignature() {
			// for some reason moving it from isPhysical() into its own computed resolves the UI frozen issue
			return this.contractTemplate?.needsSignature
		},
		isPhysical() {
			return !this.needsSignature
		},
		isSigned() {
			return !!this.contract.signedDate
		},
		toBeSigned() {
			return !this.isSigned && (!this.contract.dueDate || (this.contract.dueDate && moment().isBefore(moment(this.contract.dueDate))))
		},
		overdue() {
			return !this.isSigned && !this.toBeSigned && this.contract.dueDate && moment().isAfter(moment(this.contract.dueDate))
		},
		backgroundColor() {
			if (this.isSigned || !this.needsSignature) {
				return '#E5F6DA'
			}
			if (this.toBeSigned) {
				return '#FFF2D4'
			}
			if (this.overdue) {
				return '#FDE0E0'
			}
			return '#DBDBDB'
		},
		textColor() {
			if (this.isSigned || !this.needsSignature) {
				return '#28500E'
			}
			if (this.toBeSigned) {
				return '#664800'
			}
			if (this.overdue) {
				return '#611C1C'
			}
			return '#DBDBDB'
		},
		backgroundColorChip() {
			if (this.isSigned || !this.needsSignature) {
				return '#64C823'
			}
			if (this.toBeSigned) {
				return '#FFB400'
			}
			if (this.overdue) {
				return '#F34545'
			}
			return '#8F8F8F'
		},
		statusText() {
			if (this.isSigned || this.isPhysical) {
				return this.$t('text.signed')
			}
			return this.$t('text.unsigned')
		},
		infoText() {
			if (this.isSigned) {
				return `${this.$t('text.signedOn')} <b>${moment(this.contract.signedDate).format('DD.MM.YYYY')} ${this.$t('text.at')} ` +
					`${moment(this.contract.signedDate).format('HH:mm:ss')}</b> by <b>${this.contract.signatureName}, ${this.contract.signaturePosition}</b>`
			}
			if (this.toBeSigned) {
				return `<b>${this.$t('text.signUntil')} ${moment(this.contract.dueDate).format('DD.MM.YYYY')}</b>`
			}
			if (this.overdue) {
				return `<b>${this.$t('text.notSignedInTime')}</b>`
			}
			return ''
		},
		issuedOnText() {
			return `${this.$t('text.issuedOn')} ${moment(this.contract.createdDate).format('DD.MM.YYYY')} ${this.$t('text.at')} ` +
				`${moment(this.contract.createdDate).format('HH:mm:ss')}`
		}
	}
}
</script>

<style scoped lang="scss">
.contract-card {
	padding: 16px;
	border-radius: 4px;
	display: flex;
	flex-direction: column;

	&-top {
		display: flex;
		height: 100%;

		&-name {
			display: flex;
			flex-direction: column;
			width: 100%;
			font-size: 17px;
			font-weight: 500;
			line-height: 22px;
			letter-spacing: -0.071px;

			>span:last-child {
				font-size: 12px;
				font-weight: 600;
				line-height: 16px;
				letter-spacing: 0.001px;
			}
		}

		&-chip {
			display: flex;
			height: 24px;
			padding: 0px 8px;
			justify-content: center;
			align-items: center;
			gap: 8px;
			border-radius: 4px;
			font-size: 13px;
			font-weight: 400;
			line-height: 18px;
			letter-spacing: 0.001px;
			color: white;
			margin-left: 16px;
		}
	}

	&-info {
		padding-top: 16px;
		font-size: 15px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: -0.037px;
	}

	&-issued {
		text-align: right;
		font-size: 12px;
		font-weight: 400;
		line-height: 16px;
		letter-spacing: 0.001px;
		color: rgba(0, 0, 0, 0.5)
	}
}
</style>