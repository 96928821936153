<template>
	<v-menu>
		<template v-slot:activator="{ props, isActive }">
			<v-btn 
				v-bind="props" 
				:class="{ isActive }"
				class="custom-button"
				elevation="0"
			>
				<div class="button-content">
					<div class="left-content">
						<v-icon v-if="prependIcon" class="mr-2">{{ prependIcon }}</v-icon>
						{{ buttonText }}
					</div>
					<div class="divider"></div>
					<v-icon class="mx-2">{{ isActive ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
				</div>
			</v-btn>
		</template>
		<v-list>
			<v-list-item 
				v-for="(option, index) in options" 
				:key="index"
				:disabled="option.disabled === true"
				:title="itemsI18n ? $t('text.' + option.key) : option.key"
				:prepend-icon="option.icon"
				link
				class="option"
				@click="$emit('option-click', option.key)"
			/>
		</v-list>
	</v-menu>
</template>

<script lang="ts">
import { type PropType } from 'vue'

type Option = {
	key: string,
	disabled?: boolean,
	icon?: string,
}
type Options = Option[]

export default {
	props: {
		buttonText: {
			type: String,
			required: true
		},
		options: {
			type: Array as PropType<Options>,
			required: true
		},
		prependIcon: {
			type: String,
			default: null
		},
		itemsI18n: {
			type: Boolean,
			default: false
		}
	},
	emits: ['option-click'],
}
</script>

<style lang="scss" scoped>
$button-height: 40px;

.custom-button {
	color: white;
	padding: 0;
	height: $button-height;
	border: 0;
	border-radius: 5px;
	font-family: 'Inter', sans-serif;
	font-size: 12pt;
	font-weight: normal;
	letter-spacing: 0;
	text-transform: none;
	background-color: #00AEEF;
	&.isActive {
		background-color: #00688F;
	}
	.button-content {
		display: flex;
		align-items: center;
		height: 100%;
		padding: 0;

		.left-content {
			display: flex;
			align-items: center;
			padding: 0 16px;
		}

		.divider {
			width: 1px;
			height: $button-height;
			background-color: rgba(255, 255, 255, 0.3);
		}
	}
}
.option {
	cursor: pointer !important;
	:deep(.v-list-item__prepend .v-list-item__spacer) {
		width: 12px;
	}
}
</style>