// this file is a ui shim for some imports in entities and Executives.
// see dashboard/vite.config.json > StringReplace to see how this is installed.

// client shims for mikro-orm replacing imports like this:
// import { Entity, EntityManager, ManyToOne, MikroORM, PrimaryKey, Property } from '@mikro-orm/postgresql'

export function Entity(options) { /*return (target) => console.log('Entity', options, target)*/ }
export function Property(options) { /*return (target, name) => console.log('Property', options, target, name)*/ }
export function ManyToOne() {}
export function OneToMany() {}
export function OneToOne() {}
export function ManyToMany() {}
export function PrimaryKey() {}
export function Check() {}
export function Unique() {}
export function Index() {}
export function Indexed() {}
export function Enum() {}
export function Cascade() {}
export function Collection() {}
export function Embedded() {}
export function Embedable() {}
export function Formula() {}
export function Filter() {}

export class MikroORM {}
export class EntityManager {}
export class EntityRepository {}
export class EntityRepositoryType {}

export function Controller() {}
export function Get() {}
export function Post() {}
export function Put() {}
export function Delete() {}
export function Patch() {}
export function Body() {}
export function Query() {}
export function Headers() {}
export function Request() {}
export function Response() {}
export function Param() {}
export function ParseIntPipe() {}

// client shims for rmi-server-util
export function register() {}

// client shims for context-util
export const context = {}

// client shims for permission-util
export function CheckPolicy() {}
export function Resource(type, index) { return (t,n,d) => {} }
export const permissions = {}